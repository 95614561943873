import React, { Component } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import "./App.scss";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const Login = React.lazy(() => import('./components/Login/Login'));
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/" name="Login" render={props => <Login {...props} />} />
            <Route path="/" name="DefaultLayout" render={props => <DefaultLayout {...props} />} />       
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;


